const Robots = () => {
    return (
        <div className="content">
		<div className="season">
			<section className="seasonHeading">
				<h1 className="seasonName">POWERPLAY</h1>
				<p className="year"><span className="between">2022 &#183; 2023</span></p>
				<p>This was our first season in FTC, so it was very exciting for us. We hadn't established a clear process for design at the start, but eventually figured out the best way for us to design a robot. We would build one, test it out, locate the shortcomings, and then attempt to make a better robot. You can find the season introduction video <a href="https://www.youtube.com/watch?v=HsitvZ0JaDc&ab_channel=FIRSTTechChallenge">here</a>.</p>
			</section>
			<section className="robot">
				<div className="robotText">
					<img className="float-right" src="/Images/robotV3.jpg"  alt="first robot" width="300" />
					<h2>Our Robot</h2>
					<p>
						Our robot for POWERPLAY consisted of a drivetrain, four mecanum wheels, a verticle linear slide, a claw, and four motors.
						The drivetrain is small enough to fit between two junctions without bumping into them but large enough to fit all of our elements in, including the sideways motor we use to power the linear slide.
						We had a claw mounted on a linear slide. The clas had two servos, one to power each side of the claw in order to have the claw open up to 180 degrees to decrease the chance of knocking down the cone.
						The claw is also covered with two strips of rubber to help it crip better. We found that the linear slide was the fastest, so we ended up using that.
						This robot also has a roof made out of two pieces of plastic. 
						The purpose of this was to prevent cones from falling into the robot, as that would be very detrimental.
						A robot can only have one cone at a time, so if a cone fell into our robot, getting more cones would be penalty, so we wouldn't be able to do anything.
					</p>
				</div>
			</section>
		</div>
		<div className="season">
			<section className="seasonHeading">
				<h1 className="seasonName">UNKNOWN</h1>
				<p className="year"><span className="between">2023 &#183; 2024</span></p>
				<p>Our Strategy was similar to our last season, but we were more efficient.You can find the season introduction video <a href="https://www.youtube.com/watch?v=HsitvZ0JaDc&ab_channel=FIRSTTechChallenge">here</a>.</p>
			</section>
			<section className="robot">
				<div className="robotText">
					<img className="float-right" src="/Images/placeholder3.jpg"  alt="first robot" width="300" />

					<h2>Our Robot</h2>
					<p>
						Our robot for POWERPLAY consisted of a drivetrain, four mecanum wheels, a verticle linear slide, a claw, and four motors.
						The drivetrain is small enough to fit between two junctions without bumping into them but large enough to fit all of our elements in, including the sideways motor we use to power the linear slide.
						This robot also has a roof made out of two pieces of plastic. 
						The purpose of this was to prevent cones from falling into the robot, as that would be very detrimental.
						A robot can only have one cone at a time, so if a cone fell into our robot, getting more cones would be penalty, so we wouldn't be able to do anything.
					</p>
				</div>
			</section>
		</div>
	</div>
    )
}
export default Robots;