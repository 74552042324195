const Sponsors = () => {
    return (
        <div className="content">
		<h1>Our Sponsors</h1>
		<p>
			Though last year was our first year in FTC, we were able to gain a few sponsorships from Comcast, DEKA Research & Development Corp., goBilda, TechAction, and BAE Systems. 
			We would like to give a special thanks to Comcast for sponsoring us!
			Click the images to go to the sponsors' websites! 
			We are very grateful to our sponsors! 
			If you would like to sponsor us, please email us!
		</p>
		<div className="sponsorContainer">
			<div>
				<a href="https://corporate.comcast.com/"><img className="sponsorImage" src="/Images/comcastSponsor.jpg" alt="Comcast logo"/></a>
			</div>
			<div>
				<a href="http://www.dekaresearch.com/"><img className="sponsorImage" src="/Images/dekaSponsor.jpg" alt="deka logo"/></a>
			</div>
			<div>
				<a href="https://www.gobilda.com/"><img className="sponsorImage" src="/Images/gobildaSponsor.jpg" alt="gobilda logo"/></a>
			</div>
			<div>
				<a href="https://www.baesystems.com/en/home"><img className="sponsorImage" src="/Images/baeSponsor.jpg" alt="bae systems logo"/></a>
			</div>
			<div>
				<a href=""><img className="sponsorImage" src="/Images/techAction.jpg" alt="techAction logo"/></a>
			</div>
		</div>
	</div>
    )
}
export default Sponsors