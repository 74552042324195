import ImageSlider from "../Components/Slider"
import { SliderData } from "../Components/SliderData"
const Home = () => {
    return (
        <div className="content">
            <ImageSlider slides={SliderData} shouldRender={true}/>
            <section className="container">
                <h3 className="heading">Highlights</h3>
                <div className="textContainer">
                    <p className="highlightHeading"><b>From <span className="between hightlightSeason">POWERPLAY:</span></b></p>
                    <p><span className="bulletPoint">&#183;</span><b>Mechanicsville Qualifier, VA:</b> 5-0 in Qualifying Matches, 2nd Seed Captain</p>
                    <p><span className="bulletPoint">&#183;</span><b>DC Qualifier, DC:</b> 5-0 in Qualifying Matches, Winning Alliance Captain</p>
                    <p><span className="bulletPoint">&#183;</span><b>Laurel Qualifier, MD:</b> 5-0 in Qualifying Matches, 1st Seed Captain</p>
                    <p><span className="bulletPoint">&#183;</span><b>Chesapeake Regional Championship:</b> 2-3 in Qualifying Matches, Rank 15</p>
                </div>
            </section>
            <section className="container">
			<h3 className="heading">Future Events</h3>
			<div className="textContainer">
				<p><span className="bulletPoint">&#183;</span><b>Season Kickoff:</b> In September, the new season will start. See the <a href="/events">events page</a> for more details</p>
				<p><span className="bulletPoint">&#183;</span><b>First Qualifier:</b> Not known as of now; be sure to regularly check our <a href="/events">events page</a> for updates!</p>
				
			</div>
		</section>
        </div>
    )
}
export default Home