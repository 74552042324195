const Outreach = () => {
    return (
        <div className="content">
		<h1 className="outreachSeason">CENTER STAGE: 2023-2024</h1>
		<section className="event1">
			<div className="date">
				<h2 className="numberDate">23</h2>
				<h2>April</h2>
			</div>
			<div className="description">
				<h2>Robot Demonstration at Rockville Science Fair</h2>
				<p className="overview">On April 23, 2023, our team had an exhibit at the Rockville Science Fair which took place at Montgomery College. We talked to people, demonstrated the robot, and more.</p>
				<a href="" className="viewDetails">View Details</a>
			</div>
		</section>
		<h1 className="outreachSeason">POWERPLAY: 2022-2023</h1>
		<section className="event1">
			<div className="date">
				<h2 className="numberDate">??</h2>
				<h2>January</h2>
			</div>
			<div className="description">
				<h2>Scrimmage</h2>
				<p className="overview">On [date] we went to a scrimmage.</p>
				<a href="" className="viewDetails">View Details</a>
			</div>
		</section>
		<section className="event1">
			<div className="date">
				<h2 className="numberDate">01</h2>
				<h2>October</h2>
			</div>
			<div className="description">
				<h2>Scrimmage</h2>
				<p className="overview">On October 1, 2022, we went to a scrimmage. This was very useful to us since we got to test out our robot in a competition like setting. It helped us identify flaws and advantages within our current design and then modify it.</p>
				<a href="" className="viewDetails">View Details</a>
			</div>
		</section>
	</div>
    )
}
export default Outreach