import AppContext from "../AppContext";
import { useContext } from "react";
const LogOut = () => {
    const [state, dispatch] = useContext(AppContext)
    if (state.loggedIn) {
        dispatch({ type: 'Logout'})
    }
    return(<h1 className={`${state.hiddenNavIsShowing ? 'noOverflow' : ''}`}>You have successfully logged out.</h1>)
}

export default LogOut;