import { useEffect, useState } from "react"
import { useNavigate, Navigate } from "react-router-dom";
// import { redirect } from 'react-router';
import axios from "axios";
import AppContext from "../AppContext";
import { useContext } from "react";
const Login = () => {
  const API = axios.create({
    baseURL: "http://192.168.0.159:8888",
  });
  const navigate = useNavigate()
    const [state, dispatch] = useContext(AppContext)
    const [username, setName] = useState(" ")
    const [password, setPassword] = useState(" ")
    const [nav, setNav] = useState(false)
    const [resp, setResp] = useState(" ")
    // const [name, setName] = useState(" ");
    // import axios from 'axios';

// useEffect(() => {
//     axios.get("http://192.168.0.159:8888/login?username=orca3&password=12345")
//     .then((response) => {
//         const ex = response.data;
//         console.log(ex);
//      })});
    const handleSubmit = (e) => {
        e.preventDefault()
        submit({username, password})
    }
     const submit = async ({ username, password }) => {
        try {
            console.log(username)
            console.log(password)
            const response = await API.get("/login?username=" + username + "&password=" + password).then((response) => {
            if (response?.data.succeeded) {
              dispatch({ type: 'Login', currentAccount: username})
              console.log("Logged In")
            
              // if (state.loggedIn) {
                console.log("I am logged in and this code works")
                navigate("/")
              // }
            } else {
              console.log("Failed to Log In")
            }
            // return redirect("/")
          })
          

          // Do something with the response data if needed
          
        } catch (error) {
          // Handle any errors that might occur during the API call
          console.error("Error fetching data:", error);
        }
      };
    if (nav) {
        <Navigate to="/"/>
    }
    return (
        <div className="page-container">
        <main className={`formSignin ${state.hiddenNavIsShowing ? 'noOverflow' : ''}`}>
            <form>
                <p>Username: <input type="text" onChange={e => setName(e.target.value)}></input></p>
                <p>Password: <input type="password" onChange={e => setPassword(e.target.value)}></input></p>
                <button type="submit" onClick={handleSubmit}>Submit</button>
            </form>
        </main>
        </div>
    )
}
export default Login