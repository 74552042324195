// import trophyImage from "../trophyImage.jpg";
// import yix from "../Yixuan.png"

export const SliderData = [ 
    {
        image: "/Images/robotV3.jpg"
    },
    {
        image:  "/Images/robotV1.jpg"
    }
]