import {Link, useLocation, useNavigate} from "react-router-dom";
import AppContext from "../AppContext";
import { useContext } from "react";

const NavBar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    console.log(location.pathname);
    const [state, dispatch] = useContext(AppContext)
    console.log(state.isAdmin)
    return (
        <nav className="navBar">
			<Link to="/" className={`${location.pathname === "/" ? 'active' : ''}`}>Home</Link>
			<Link to="/robots"className={`${location.pathname === "/robots" ? 'active' : ''}`} >Robots</Link>
            <Link to="/outreach"className={`${location.pathname === "/outreach" ? 'active' : ''}`} >Outreach</Link>
            <Link to="/members" className={`${location.pathname === "/members" ? 'active' : ''}`}>Members</Link>
            <Link to="/events" className={`${location.pathname === "/events" ? 'active' : ''}`}>Events</Link>
            <Link to="/sponsors" className={`${location.pathname === "/sponsors" ? 'active' : ''}`}>Sponsors</Link>
            <Link to="/about" className={`${location.pathname === "/about" ? 'active' : ''}`}>About Us</Link>
            {state.isAdmin ? <Link to="/manage" className={`${location.pathname === "/about" ? 'active' : ''}`}>Manage Accounts</Link> : <Link to="/manage" className="hidden">Manage Accounts</Link>}
            {/* <Link to="/images" className={`${location.pathname === "/images" ? 'active' : ''}`}>Images</Link> */}
		</nav>
    )
}
export default NavBar