import AppContext from "../AppContext";
import { useContext } from "react";
import { Link } from "react-router-dom";
const Header = () => {
    const [state, dispatch] = useContext(AppContext)
    return (
        <div>
            <section className="banner">
                <div className="bannerLogo">
                    <img src="/Images/Logo.svg" alt="logo" width="100" />
                </div>
                <div className="bannerHeading">
                    <h1>Orca Robotics</h1>
                </div>
                <div className="buttons">
                    {!state.loggedIn ? <Link to="/login"><button className="logIn">Log In</button></Link> : <Link to="/logout"><button className="logIn">Log Out</button></Link>}
                    {!state.loggedIn ? <Link to="/login"><button className="signUp">Register</button></Link> : <Link><button className="signUp">Settings</button></Link>}
                </div>
		    </section>
        </div>
    )
}

export default Header;