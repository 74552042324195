const AboutUs = () => {
    return (
        <div className="content">
            <section className="aboutUs">
                <div className="about">
                    <h3 className="aboutHeading">Who We Are</h3>
                    <div className="aboutContent">
                        <img src="/Images/teamPhoto1.jpg" className="float-right margin-top-bottom-10" alt="team photo" />
                        <p className="aboutText">
                            We are a robotics team consisting of six people in middle school and high school. Our youngest
                            member is in 7th grade and our oldest is in 9th. Our team was created in the Fall of 2022,
                            bringing together a variety of people who all shared one characteristic; the desire to learn.
                            Since then, our team has put in countless hours of hard work to make our current achievements
                            possible.
                        </p>
                    </div>
                </div>
            </section>
            <section className="season">
			<div>
				<h3 className="seasonHeading">2022-2023 Season Achievements</h3>
			</div>
			<div className="seasonContent">
				<div className="qual1">
					<h4>First Qualifier: Mechanicsville, VA</h4>

					<p className="qualText">
						We placed Rank 2 in the qualifying matches, with a win-loss rate of 5-0, and a high score of 179. 
						Winning all of our matches moved us to elimination rounds.
						We partnered with team 11112, RoboLords, and team 8535, the Techno Tardigrades.
						In the semifinals, we had a win-loss rate of 0-1. 
						Though we were a little disappointed, we still enjoyed being there since if was a true learning experience.
						This was our team's first time at a qualifier, so it was very exciting and stressful for many of us.
						We are very proud of our team.
					</p>
				</div>
				<div className="qual2">
					<h4>Second Qualifier: DC</h4>
					<p>
						We placed Rank 1 in the qualifying matches, with a win-loss rate of 5-0, and a high score of 178.
						Like previously, we went to the elimination rounds and were allliance captains fo the first alliance.
						We partnered with team 3583, Cybirds, and team 8702, Innovotics.
						We did well in semifinals to move onto the finals, which we got 2-0 in. 
						This meant we advanced to state!
						We were very proud since it took us a lot of hard work to get there.
					</p>
				</div>
				<div className="qual1">
					<h4>Third Qualifier: Laurel, MD</h4>
					<p>
						We placed Rank 1 in the qualifying matches, with a win-loss rate of 5-0. 
						That allowed us to go to the elimination matches.
						As Rank 1, we were the captain of the first alliance.
						Team 21232, No Team, and team 20123, Cryptic, were our allies for this.
						In the elimination rounds, we won the semifinal matches and advanced to the finals, where we had a win-loss rate of 1-3.
						Though we already qualified, we still went to this qualifier to gain more experience and allow our backup drivers get a chance to experience driving in a qualifier.
					</p>
				</div>
				<div className="qual2">
					<h4>Chesapeake Regional Championship</h4>
					<p>
						We placed Rank 15 in the qualifying matches, with a win-loss rate of 2-3, and a high score of 171.
						Regionals was very stressful for all of us, since it was the next level of competition, and we would get more advanced competitors.
						We are very proud of our team for doing their best 
						After all, almost all of us were new to FTC then, so it was a learning experience. 
						Next time, we hope that we can do even better.
					</p>
				</div>
			</div>
		</section>
        </div>
    );
};

export default AboutUs;
