const Events = () => {
	const PowerPlay = [["11", "February", "Chesapeake Championship", "Our first time at the Chesapeake Championship. We placed 15th with a high score of 201."], ["21", "January", "Laurel Qualifier", "Our second qualifier of our first season in FIRST Tech Challenge. We were first place in the DC qualifier, which also got us into States."], ["14", "January", "DC Qualifier", "Our second qualifier of our first season in FIRST Tech Challenge. We were first place in the DC qualifier, which also got us into States."], ["03", "December", "Mechanicsville Qualifier", "Our first qualifier of our first season in FIRST Tech Challenge."], ["10", "September", "Season Kickoff", "On September 10, 2022, the POWERPLAY, presented by Raytheon Technologies was revealed and the season officially started. You can check out the broadcast here or just the game animation here."]]
    return (
        <div className="content">
		<h1>Upcoming Events</h1>
		<section className="event1">
			<div className="date">
				<h2 className="numberDate">09</h2>
				<h2>September</h2>
			</div>
			<div className="description">
				<h2>2023-2024 Season Kickoff</h2>
				<p className="overview">On September 9, 2023, the new season's game will be released, marking the official start of the season for our team.</p>
				<a href="" className="viewDetails">View Details</a>
			</div>
		</section>
		<h1>POWERPLAY (2022-2023)</h1>
		<section className="event1">
			<div className="date">
				<h2 className="numberDate">21</h2>
				<h2>January</h2>
			</div>
			<div className="description">
				<h2>Laurel Qualifier</h2>
				<p className="overview">Our second qualifier of our first season in FIRST Tech Challenge. We were first place in the DC qualifier, which also got us into States.</p>
				<a href="" className="viewDetails">View Details</a>
			</div>
		</section>
		<section className="event1">
			<div className="date">
				<h2 className="numberDate">14</h2>
				<h2>January</h2>
			</div>
			<div className="description">
				<h2>DC Qualifier</h2>
				<p className="overview">Our second qualifier of our first season in FIRST Tech Challenge. We were first place in the DC qualifier, which also got us into States.</p>
				<a href="" className="viewDetails">View Details</a>
			</div>
		</section>
		<section className="event1">
			<div className="date">
				<h2 className="numberDate">03</h2>
				<h2>December</h2>
			</div>
			<div className="description">
				<h2>Mechanicsville Qualifier</h2>
				<p className="overview">Our first qualifier of our first season in FIRST Tech Challenge.</p>
				<a href="" className="viewDetails">View Details</a>
			</div>
		</section>
		<section className="event1">
			<div className="date">
				<h2 className="numberDate">10</h2>
				<h2>September</h2>
			</div>
			<div className="description">
				<h2>Season Kickoff</h2>
				<p className="overview">On September 10, 2022, the POWERPLAY, presented by Raytheon Technologies was revealed and the season officially started. You can check out the broadcast <a href="https://www.youtube.com/watch?v=DhhTHuA1Z50&t=39s&ab_channel=FIRSTTechChallenge">here</a> or just the game animation <a href="https://www.youtube.com/watch?v=HsitvZ0JaDc&t=2s&ab_channel=FIRSTTechChallenge">here</a>.</p>
				<a href="" className="viewDetails">View Details</a>
			</div>
		</section>

	</div>
    );
};

export default Events;
