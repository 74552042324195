import logo from './logo.svg';
import './App.css';
import Header from "./Sections/Header"

import Robots from "./Routes/Robots"
import Footer from "./Sections/Footer"
import NavBar from "./Sections/NavBar"
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useReducer } from 'react';
import Members from './Routes/Members';
import Events from './Routes/Events';
import Home from "./Routes/Home";
import AboutUs from './Routes/AboutUs';
import Login from './Routes/Login';
import AppContext from './AppContext';
import LogOut from './Routes/LogOut';
import Outreach from './Routes/Outreach';
import Sponsors from "./Routes/Sponsors"
import Images from './Routes/Images';
import { SliderData } from './Components/SliderData';
import ImageSlider from './Components/Slider';

const App = () => {
  const reducer = (state, action) => {
    switch (action.type) {
      case 'Login':
        if (action.currentAccount == "orca3") {
        return {...state, loggedIn: true, currentLogin: action.currentAccount, isAdmin: true}
        } else {
          return {...state, loggedIn: true, currentLogin: action.currentAccount}
        }

      case 'Logout':
        console.log("Logged Out Successfully")
        return {...state, loggedIn: false, currentLogin: "", isAdmin: false}
      case 'changePage':
        return {...state, currentPage: action.currentPage}
      case 'toggleHiddenNav':
        return {...state, hiddenNavIsShowing: !state.hiddenNavIsShowing}
      case 'hideHiddenNav':
        return {...state, hiddenNavIsShowing: false}
      
    }
  }
  const initialState = {
    loggedIn: false, 
    currentPage: "home",
    hiddenNavIsShowing: false,
    currentLogin: "",
    isAdmin: false
  }
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <AppContext.Provider value={[state, dispatch]}>
    <BrowserRouter>
      <div className="App">
        <Header />
        <NavBar />
        <Routes>
          <Route path="/robots" element= { <Robots /> }></Route>
          <Route path="/members" element={<Members />}></Route>
          <Route path="/events" element={<Events />}></Route>
          <Route exact path="/" element= { <Home />}></Route>
          <Route path="/about" element={<AboutUs />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/logout" element={<LogOut />}></Route>
          <Route path="/outreach" element={<Outreach />}></Route>
          <Route path="/sponsors" element={<Sponsors />}></Route>
          {/* <Route path="/images" element={<ImageSlider slides={SliderData} shouldRender={true}/>}></Route> */}
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
    </AppContext.Provider>
  )
}

export default App;
