const Members = () => {
    return (
            
            <div className="content">
                <section className="member">
                    <img src="/Images/placeholder1.jpg" className="float-left" />
                    <h3 className="name">Christopher Wang</h3>
                    <p className="role"><span className="between">Captain, Main Programmer</span></p>
                    <p>Christopher is the captain of the team and also the main programmer. 
					He also does some CAD design and builds the robot.
					Last season, Christopher was a main driver alongside Yixuan Li.
					Christopher is 14 years old and in 9th grade.
					His hobbies include playing games, talking with his friends, and learning about code. 
					Christopher enjoys coding the robot and has dedicated a lot of time to improving his coding skils.</p>

                </section>
                <section className="member">
                    <img src="/Images/placeholder4.jpg" className="float-right" />
                    <h3 className="name">Sophia Wang</h3>
                    <p className="role"><span className="between">Co-Captain, Main CAD Designer</span></p>
                    <p>Sophia is the co-captain of the team as well as the main CAD designer. 
					She and her brother Christopher founded this team in the Fall of 2022 and have been working hard ever since.
					Sophia uses Onshape to design parts to print out with the 3-D printer. 
					She is 13 years old and in 8th grade. 
					Her hobbies include reading fiction novels, playing piano, and doing CAD.
					Although she is new to robotics, she aims to do her best.</p>
                </section>
                <section className="member">
                    <img src="/Images/placeholder6.jpg" className="float-left" />
                    <h3 className="name">Yixuan Li</h3>
                    <p className="role"><span className="between">Builder, Main Driver</span></p>
                    <p>Yixuan is a 9th grader at Montgomery Blair HS. He is a builder and driver, and plays violin and reads in his free time.</p>
                </section>
                <section className="member">
                    <img src="/Images/placeholder5.jpg" className="float-right" />
                    <h3 className="name">Daisy Hu</h3>
                    <p className="role"><span className="between">Builder, Secondary CAD Designer</span></p>
                    <p>Daisy is 13 years old, and an 8th grader at Lakelands Park MS. She helps with building and coming up with new designs. She likes to swim, sketch, and do math.</p>
                </section>
                <section className="member">
                    <img src="/Images/placeholder7.jpg" className="float-left" />
                    <h3 className="name">Malinda Zhu</h3>
                    <p className="role"><span className="between">Outreach Coordinator, Secondary CAD Designer</span></p>
                    <p>Malinda is 12 years old and a 7th grader at Eastern MS. She is one of the backup drivers for this team and helps manage outreach. Malinda is also a builder and occasionally CADs. She likes listening to music, oil painting, reading, and collecting anything that's remotely watermelon.</p>
                </section>
                <section className="member">
                    <img src="/Images/placeholder1.jpg" className="float-right" />
                    <h3 className="name">Katherine Zhang</h3>
                    <p className="role"><span className="between">Outreach Coordinator, Backup Driver</span></p>
                    <p>Kathy Zhang is a 9th grader at Winston Churchill High School (idk how to spell it dont quote me on that) and is the main outreach coordinator and backup driver on the team. She enjoys taking naps and playing horror games.</p>
                </section>
            </div>
    )
}
export default Members
