const Footer = () => {
    return (
        <footer>
			<div className="columnFooter">
				<div className="columnFooterLogo">
					<a href="/"><img src="/Images/Logo.svg" className="footerLogo"/></a>
					<h2 className="logoName">Orca Robotics</h2>
				</div>
				<div className="footerVerticalLine" />
				<div>
					<h4>About Us</h4>
					<a href="/">Home</a>
					<a href="/about">About</a>
					<a href="/robots">Robots</a>
					<a href="/outreach">Outreach</a>
					<a href="/members">Team Members</a>
					<a href="/events">Events</a>
					<a href="/sponsors">Sponsors</a>
				</div>
				<div>
					<h4>Contact Us</h4>
					<a href="mailto:orcaroboticsusa@gmail.com">Email</a>
					<a href="">Form</a>
					{/* <a href="sponsor.html">Sponsor Us</a> */}
				</div>
				<div>
					<h4>Social Media</h4>
					<a href="https://www.instagram.com/orca_robotics_21587/">Instagram</a>
					<a href="">Facebook</a>
					<a href="">Youtube</a>
					<a href="">Twitter</a>
				</div>
			</div>
			<div className="siteInfo">
				<section className="socialMediaContainer">
						<a href="https://www.instagram.com/orca_robotics_21587/" ><img className="socialMediaLogo" src="/Images/instagram.svg" /></a>
						<a href=""><img className="socialMediaLogo" src="/Images/facebook.svg" /></a>
						<a href=""><img className="socialMediaLogo" src="/Images/youtube.svg"/></a>
						<a href=""><img className="socialMediaLogo" src="/Images/twitter.svg"/></a>
				</section>
				<section className="copyright">
					<p>&copy; Orca Robotics 2023</p>
				</section>
			</div>
			
		</footer>
    )
}

export default Footer